<template>
  <div class="page-container">
    <page-header></page-header>
    <survey :p-actions="actions" :show-header="false" :order-page="getWorkOrder">
      <template slot="table-title">
        <div>
          <div class="table-title">{{ $route.meta.title }}</div>
          <div class="segmentation-button-group">
            <div class="item item-active">踏勘工单</div>
            <div class="item" @click="$router.replace('/admin/service-construction')">施工工单</div>
          </div>
        </div>
      </template>
    </survey>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Survey from "@/views/survey/Survey";
import {getWorkOrder} from "@/api/common";
export default {
  name: "Orders",
  components: {Survey, PageHeader},
  data() {
    return{
      getWorkOrder,
      actions: [
        {
          action: 'transfer',
          label: '转单',
          type: 'primary',
          permission: 'gd:zd',
          showAction: item => {
            // 审核完成后不能转单
            return !['SUCCESS'].includes(item.state)
          }
        },
        {
          action: 'seal',
          label: '封单',
          type: 'primary',
          permission: 'gd:jf',
          showAction: item => {
            // 未封单且未完成审核
            return !['CLOSE', 'SUCCESS'].includes(item.state)
          }
        },
        {
          action: 'unblock',
          label: '解封',
          type: 'primary',
          permission: 'gd:jf',
          showAction: item => {
            return item.state === 'CLOSE'
          }
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.table-title{
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 20px;
  color: #18191A;
  font-weight: bold;
}
.segmentation-button-group{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 168px;
  height: 40px;
  background: #EEF1F5;
  border-radius: 4px;
  .item{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 32px;
    border-radius: 4px;
    background-color: #ffffff00;

    font-size: 14px;
    color: #2F3133;

    cursor: pointer;
  }
  .item-active{
    background-color: #ffffff;
    color: #18191A;
  }
}
</style>
